@import 'addtoevent';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@include addtoevent;

body {
  margin: 0;
  overflow-x: hidden;
}

.marketplace-page {
  ate-marketplace-page-header {
    margin-top: padding(medium);
  }

  ate-marketplace-filter-header {
    margin-top: padding(small);

    @include breakpoint(map-get($breakpoints, medium)) {
      margin-top: padding(medium);
    }
  }

  ate-pagination {
    margin-top: padding(base);
  }
}

ate-marketplace-page-header {
  margin-bottom: padding(medium);
}

.error-chunk-modal {
  background-color: white;
  opacity: 0.5 !important;
}

a {
  color: #a93a61;
  font-weight: bold;
}

// Modal overrides
ate-form-flow-modal {
  dialog::backdrop {
    overflow-y: hidden;
  }
  dialog[open] {
    width: 720px;
    height: 620px;
    padding: 0;

    .body {
      width: 100%;
      margin: 0;
      max-height: 483px;
    }

    footer {
      width: auto;
      display: flex;
      width: 100%;

      .back-button {
        width: fit-content;
      }
    }
  }
}

